import React, { createContext, FC, useCallback, useMemo } from 'react';
import { Counter } from '../types';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { CounterService } from '../services';

const initialState = {
  declarations: 0,
  unknownDeclarations: 0,
  customsReadyDeclarations: 0,
  customsTasks: 0,
  supports: 0,
};

export const CounterContext = createContext<{ state: Counter }>({
  state: initialState,
});

export const CounterProvider: FC = ({ children }) => {
  const { data } = useQuery<Counter>(
    useCallback(async () => {
      const result = await CounterService.get();

      if (result.status === 200) {
        return result.data;
      } else {
        return initialState;
      }
    }, []),
    useMemo(() => ({ initialData: initialState, polling: 10000 }), []),
  );

  return <CounterContext.Provider value={{ state: data }}>{children}</CounterContext.Provider>;
};
