import React, { useState, useCallback, ChangeEvent } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Input, message } from 'antd';
import * as Icons from '@ant-design/icons';


import { HeaderButton } from './header-button';
import { DeclarationsService } from '../../declarations/services';


export const Form = styled.form`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px;
`;

export const QuickSearch = () => {
    const [isInputShown, setIsInputShown] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const history = useHistory();


    const checkDeclaration = useCallback(
        async (track_code: string) => {
            const result = await DeclarationsService.getAll({ track_code });
            if (result.status === 200) {
                if (result.data && result.data.length) {
                    const id = result.data[0].id;
                    history.push(generatePath('/declarations/:id/details', { id }));
                }
            } else {
                message.error(result.data);
            }
        },
        [history],
    );

    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            checkDeclaration(searchInput);
        },
        [checkDeclaration, searchInput],
    );

    const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    }, []);

    const handleBlur = useCallback(() => {
        setIsInputShown(false);
    }, []);

    const onButtonClick = useCallback(() => {
        setIsInputShown(true);
    }, []);

    return (
        <Form onSubmit={onSubmit}>
            {isInputShown && <Input autoFocus={true} placeholder='İzləmə kodu' value={searchInput} onChange={onInputChange} onBlur={handleBlur} />}
            <HeaderButton onClick={onButtonClick} icon={<Icons.SearchOutlined />}></HeaderButton>
        </Form>
    );
};
