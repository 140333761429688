import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { MeContext } from '../../me/context/context';
import { useParcels } from '../hooks/use-parcels';
import { ParcelService } from '../services';
import { message, Modal } from 'antd';
import { Parcel, ParcelResult } from '../types';

export type ParcelContextValues = {
  selectionDialogVisible: boolean;
  setSelectionDialogVisible: (value: boolean) => void;
  closeParcel: () => void;
  openSelectionDialog: () => void;
  closeSelectionDialog: () => void;
  handleSubmitSucceed: () => void;
  onSetQuery: (field: string, value: any) => void;
  parcels: ParcelResult | null;
  currentParcel: Parcel | null;
};

export const ParcelContext = createContext<ParcelContextValues>({
  selectionDialogVisible: false,
  setSelectionDialogVisible: () => null,
  closeParcel: () => null,
  openSelectionDialog: () => null,
  closeSelectionDialog: () => null,
  handleSubmitSucceed: () => null,
  onSetQuery: () => { },
  parcels: null,
  currentParcel: null,
});

export const ParcelProvider: FC = ({ children }) => {
  const { state: userState } = useContext(MeContext);
  const [selectionDialogVisible, setSelectionDialogVisible] = useState<boolean>(false);
  const [query, setQuery] = useState<Record<string, any>>({});

  const { reFetch, data } = useParcels(query);

  const onSetQuery = useCallback((field: string, value: any) => {
    setQuery({
      ...query,
      [field]: value
    });
  }, [query])

  const currentParcel = useMemo<Parcel | null>(() => data?.available.find(({ user }) => user?.id === userState.user.data?.id) || null, [
    data,
    userState.user.data,
  ]);

  const closeParcel = useCallback(() => {
    const action = async () => {
      const result = await ParcelService.close(currentParcel?.id || 0);

      if (result.status === 200) {
        await reFetch();
      } else {
        message.error(result.data);
      }
    };

    Modal.confirm({ title: 'Təsdiqlə', content: 'Kolini bağlamaq istədiyinizə əminsinizmi?', onOk: action });
  }, [currentParcel?.id, reFetch]);

  const openSelectionDialog = useCallback(async () => {
    setSelectionDialogVisible(true);
  }, []);

  const closeSelectionDialog = useCallback(() => {
    setSelectionDialogVisible(false);
  }, []);

  const handleSubmitSucceed = useCallback(async () => {
    closeSelectionDialog();
    await reFetch();
  }, [closeSelectionDialog, reFetch]);

  return (
    <ParcelContext.Provider
      value={{
        selectionDialogVisible,
        setSelectionDialogVisible,
        closeParcel,
        openSelectionDialog,
        closeSelectionDialog,
        onSetQuery,
        handleSubmitSucceed,
        parcels: data,
        currentParcel,
      }}
    >
      {children}
    </ParcelContext.Provider>
  );
};
