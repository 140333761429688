import { DeclarationFormData } from '../types/form';
import { UnknownDeclaration, UnknownDeclarationApi } from '../types/unknown';

export class UnknownDeclarationMappers {
  public static oneFromApi(data: UnknownDeclarationApi): UnknownDeclaration {
    return {
      id: data.id,
      globalTrackCode: data.global_track_code,
      trackCode: data.track_code,
      weight: data.weight ? parseFloat(data.weight) : null,
      height: data.height ? parseFloat(data.height) : null,
      width: data.width ? parseFloat(data.width) : null,
      depth: data.depth ? parseFloat(data.depth) : null,
      price: data.price ? parseFloat(data.price) : null,
      partner: data.partner_id && data.partner_name ? { id: data.partner_id, name: data.partner_name } : null,
      deliveryPrice: data.delivery_price ? parseFloat(data.delivery_price) : null,
      thisMonthPrice: data.last_30_days ? parseFloat(data.last_30_days) : null,
      quantity: data.quantity,
      type: data.type === 1 ? 'liquid' : 'other',
      shop: data.shop_name,
      plan: !!data.tariff_category_id && !!data.tariff_category_name ? { id: data.tariff_category_id, name: data.tariff_category_name } : null,
      status: !!data.state_id && !!data.state_name ? { id: data.state_id, name: data.state_name } : null,
      productType: !!data.product_type_id && !!data.product_type_name ? { id: data.product_type_id, name: data.product_type_name } : null,
      user: !!data.user_id && !!data.user_name ? { id: data.user_id, name: data.user_name } : null,
      editedBy: !!data.causer_id && !!data.causer_name ? { id: data.causer_id, name: data.causer_name } : null,
      description: data.descr,
      read: !data.is_new,
      createdAt: data.created_at,
    };
  }

  public static collectionFromApi(raw): UnknownDeclaration[] {
    return raw.data.map((item) => UnknownDeclarationMappers.oneFromApi(item));
  }

  public static formInitializer(declaration: UnknownDeclaration): DeclarationFormData {
    return {
      id: declaration.id?.toString() || '',
      userId: declaration.user?.id.toString() || '',
      measureId: '1',
      statusId: declaration.status?.id.toString() || '',
      globalTrackCode: declaration.globalTrackCode || '',
      productTypeId: declaration.productType?.id.toString() || '',
      quantity: declaration.quantity?.toString() || '',
      isSpecial: declaration.plan ? declaration.plan?.id !== 1 : false,
      partnerId: declaration.partner?.id.toString() || '',
      planId: declaration.plan?.id.toString() || '',
      isLiquid: declaration.type === 'liquid',
      description: declaration.description || '',
      voen: '',
      height: declaration.height?.toString() || '',
      width: declaration.width?.toString() || '',
      depth: declaration.depth?.toString() || '',
      branchName: '',
      weight: declaration.weight?.toString() || '',
      price: declaration.price?.toString() || '',
      shop: declaration.shop || '',
      unknownId: '',
      isUrgent: false,
      file: null,
      printSticker: false,
    };
  }
}
