import styled, { css } from 'styled-components';
import { Button, Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

export const Header = styled(Layout.Header) <{ $wide?: boolean }>`
  height: 46px !important;
  line-height: 46px !important;
  padding: 0;
  display: flex;
  justify-content: space-between;
  position: fixed;
  right: 0;
  z-index: 1;
  transition: left 0.2s;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  left: 0;

  @media screen and (min-width: 992px) {
    left: ${({ $wide }) => (!$wide ? '224px' : '46px')};
  }
`;

const HeaderIconStyles = css`
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18px;
`;

export const MenuFoldIcon = styled(MenuFoldOutlined)`
  ${HeaderIconStyles};
`;

export const MenuUnfoldIcon = styled(MenuUnfoldOutlined)`
  ${HeaderIconStyles};
`;

export const HeaderButton = styled(Button) <{ $ghost?: boolean }>`
  border: none;
  box-shadow: none;
  height: 46px;
  background-color: ${({ $ghost = true }) => ($ghost ? 'transparent' : 'rgba(0,0,0,0.1)')};
  border-radius: 0;
  
  &:not(.ant-btn-icon-only) {
    padding: 0 12px;
  }
  
  &:disabled {
    background-color: transparent;
  }
  
  &:disabled:hover {
    background-color: transparent;
  }

  &:hover {
    background-color: rgba(0,0,0,0.2)};
  }

  &::after {
    content: none;
  }
`;

export const HeaderPortalArea = styled.div.attrs({ id: 'app-header-portal-area' })`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 8px;
  box-shadow: -18px 0 18px -30px rgba(0, 0, 0, 0.5) inset, 18px 0 18px -30px rgba(0, 0, 0, 0.5) inset;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    white-space: nowrap;
  }

  & > div {
    flex: 1;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
`;
