import React from 'react';
import { Menu } from 'antd';
import * as Icons from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

import { SidebarLogo, StyledSider } from '../styled';
import { useSidebar } from '../hooks';

export const AppSidebar = () => {
  const { isOpen, activeKey, counter } = useSidebar();

  return (
    <StyledSider trigger={null} collapsible={true} width={224} collapsedWidth={46} collapsed={!isOpen}>
      <SidebarLogo>{isOpen ? <img src='/logo.svg' alt='Buta Express' /> : <img src='/logo-compact.svg' alt='Buta Express' />}</SidebarLogo>
      <Menu theme='light' mode='inline' selectedKeys={[activeKey]}>
        <Menu.Item key='/statistics' icon={<Icons.PieChartOutlined />}>
          Piştaxta
          <NavLink to='/statistics' />
        </Menu.Item>
        <Menu.Item key='/declarations' icon={<Icons.FileTextOutlined />}>
          Bağlamalar {!!counter.declarations && `(${counter.declarations})`}
          <NavLink to='/declarations' />
        </Menu.Item>
        <Menu.Item key='/declarations/minified' icon={<Icons.FileTextOutlined />}>
          Bağlama girişi {!!counter.declarations && `(${counter.declarations})`}
          <NavLink to='/declarations/minified' />
        </Menu.Item>
        <Menu.Item key='/declarations/unknowns' icon={<Icons.FileSearchOutlined />}>
          Mübah. bağlamalar {!!counter.unknownDeclarations && `(${counter.unknownDeclarations})`}
          <NavLink to='/declarations/unknowns' />
        </Menu.Item>
        <Menu.Item key='/declarations/customs' icon={<Icons.FileDoneOutlined />}>
          Bəyan edilmişlər {!!counter.customsReadyDeclarations && `(${counter.customsReadyDeclarations})`}
          <NavLink to='/declarations/customs' />
        </Menu.Item>
        <Menu.Item key='/declarations/customs/deleted' icon={<Icons.CloseCircleOutlined />}>
          Silinmiş bağlamalar
          <NavLink to='/declarations/customs/deleted' />
        </Menu.Item>
        <Menu.Item key='/customs/dns' icon={<Icons.NotificationOutlined />}>
          BBS {!!counter.customsTasks && `(${counter.customsTasks})`}
          <NavLink to='/customs/dns' />
        </Menu.Item>
        <Menu.Item key='/quick_accept' icon={<Icons.CheckCircleOutlined />}>
          Qapıda qəbul
          <NavLink to='/quick_accept' />
        </Menu.Item>
        <Menu.Item key='/flights' icon={<Icons.RocketOutlined />}>
          Uçuşlar
          <NavLink to='/flights' />
        </Menu.Item>
        <Menu.Item key='/supports' icon={<Icons.MessageOutlined />}>
          Müraciətlər {!!counter.supports && `(${counter.supports})`}
          <NavLink to='/supports' />
        </Menu.Item>
        <Menu.Item key='/refunds' icon={<Icons.RollbackOutlined />}>
          İadələr
          <NavLink to='/refunds' />
        </Menu.Item>
        <Menu.Item key='/baskets' icon={<Icons.ShoppingCartOutlined />}>
          Səbətlər
          <NavLink to='/baskets' />
        </Menu.Item>
      </Menu>
    </StyledSider>
  );
};
