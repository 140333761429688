import { localURLMaker, urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { ApiResult } from '../../../shared/utils/api-result';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';
import { formDataFlat } from '../../../shared/utils/form-data-flat';
import { NextTableServerSideData } from '../../../shared/modules/next-table/types';

import { DeclarationMappers } from '../mappers';
import { Declaration } from '../types/declaration';
import { DeclarationsWaybillService } from './waybill';
import { DeclarationsProformaInvoiceService } from './proforma-invoice';
import { DeclarationsStickerService } from './sticker';
import { DeclarationFormData, DeclarationFormDataApi, DeclarationFormErrors } from '../types/form';
import { UnknownDeclarationsService } from './unknowns';
import { DeclarationParcelService } from './parcel';
import { sanitizeObject } from '../../../shared/utils/sanitize-object';
import { DeclarationBasketService } from './basket';
import { MinifiedDeclaration } from '../types/minified-declaration';

export class DeclarationsService {
  public static Sticker = DeclarationsStickerService;
  public static Waybill = DeclarationsWaybillService;
  public static ProformaInvoice = DeclarationsProformaInvoiceService;
  public static Unknowns = UnknownDeclarationsService;
  public static Parcel = DeclarationParcelService;
  public static Basket = DeclarationBasketService;

  public static async getOneById(id: string | number): Promise<ApiResult<200, Declaration> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/declaration/info', { declaration_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResult(200, DeclarationMappers.oneFromApi(data), null);
      } else {
        return new ApiResult(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async getAll(
    query: Record<string, any>,
  ): Promise<ApiResult<200, Declaration[], NextTableServerSideData> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/v2/declaration/list', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        return new ApiResult(200, DeclarationMappers.collectionFromApi(data), { total: data.total });
      } else {
        return new ApiResult(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async getAllMinified(
    query: Record<string, any>,
  ): Promise<ApiResult<200, MinifiedDeclaration[], NextTableServerSideData> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/declaration/minilist', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        return new ApiResult(
          200,
          data.data.map((item) => DeclarationMappers.minifiedFromApi(item)),
          { total: data.total },
        );
      } else {
        return new ApiResult(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async getAllByFlightId(
    query: Record<string, any>,
    flightId: string | number,
  ): Promise<ApiResult<200, Declaration[], NextTableServerSideData> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/flights/info', { ...query, flight_id: flightId });

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        return new ApiResult(200, DeclarationMappers.collectionFromApi(data), { total: data.total });
      } else {
        return new ApiResult(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async createOrUpdate(
    values: DeclarationFormData,
    type: string = 'create',
  ): Promise<
    ApiResult<200, number | null> | ApiResult<422, DeclarationFormErrors> | ApiResult<421, Record<string, string>> | ApiResult<400 | 500, string>
  > {
    const url = type === 'create' ? urlMaker('/api/warehouse/declaration/create') : urlMaker('/api/warehouse/declaration/edit');
    const body = new FormData();

    appendToFormData<DeclarationFormDataApi>(DeclarationMappers.formDataToApi(values), body);

    try {
      const response = await caller(url, { body, method: 'POST' });

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResult(200, data?.declaration_id || null, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        const formErrors = sanitizeObject(DeclarationMappers.formErrorsFromApi(errors), [undefined]);
        const generalErrors = sanitizeObject(DeclarationMappers.generalErrorsFromApi(errors), [undefined]);

        if (!!Object.values(formErrors).length) {
          return new ApiResult(422, formErrors, null);
        } else {
          return new ApiResult(421, generalErrors, null);
        }
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async actionByIds(ids: (string | number)[], action: string): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker(localURLMaker('/api/warehouse/declaration/:action', { action }));
    const body = new FormData();

    appendToFormData(formDataFlat({ declaration_id: ids }), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async updateStatusByIds(ids: (string | number)[], stateId: number | string): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/declaration/edit/state');
    const body = new FormData();

    appendToFormData(formDataFlat({ declaration_id: ids, state_id: stateId }), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async updateReadByIds(ids: (string | number)[], read: boolean): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/isnew');
    const body = new FormData();

    appendToFormData(formDataFlat({ object_id: ids, is_new: !read, model_id: 2 }), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async getOrderUrlsById(id: number | string): Promise<ApiResult<200, string[]>> {
    const url = urlMaker('/api/warehouse/declaration/orders', { declaration_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResult(200, data, null);
      }
    } catch (e) {}

    return new ApiResult(200, [], null);
  }

  public static async getAllDeleted(
    query: Record<string, any>,
  ): Promise<ApiResult<200, Declaration[], NextTableServerSideData> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/customs/deleteddeclarations', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        return new ApiResult(200, DeclarationMappers.collectionFromApi(data), { total: data.data.length });
      } else {
        return new ApiResult(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
