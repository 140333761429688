import React, { Fragment, useContext } from 'react';
import { Button } from 'antd';
import { BasketSelection } from './basket-selection';
import { BasketContext } from '../contexts';

export const BasketProcessor = () => {
  const { selectionDialogVisible, closeParcel, openSelectionDialog, closeSelectionDialog, handleSubmitSucceed, baskets, currentBasket } = useContext(
    BasketContext,
  );

  return (
    <Fragment>
      {!currentBasket ? (
        <Button loading={baskets?.loading} onClick={openSelectionDialog} size='small' type='link'>
          Səbət seç
        </Button>
      ) : (
        <Button loading={baskets?.loading} onClick={closeParcel} size='small' type='link'>
          {currentBasket.name} səbəti bağla
        </Button>
      )}
      <BasketSelection
        baskets={baskets?.data || []}
        visible={selectionDialogVisible}
        onClose={closeSelectionDialog}
        onSubmitSucceed={handleSubmitSucceed}
      />
    </Fragment>
  );
};
