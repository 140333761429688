import { DnsTask, DnsTaskAcceptFailedResult, DnsTaskAcceptFailedResultApi, DnsTaskApi } from '../types/dns';

export class DnsMappers {
  public static oneFromApi(task: DnsTaskApi): DnsTask {
    return {
      id: task.id,
      action: task.action,
      status: { id: task.state_id, name: task.state_name },
      createdAt: task.created_at,
      declaration: {
        id: task.declaration_id,
        trackCode: task.track_code,
        globalTrackCode: task.global_track_code,
        weight: task.weight ? parseFloat(task.weight) : null,
        basket: task.basket_id ? { id: task.basket_id, name: task.basket_name } : null,
        quantity: task.quantity,
        user: { id: task.user_id, name: task.user_name },
        status: { id: task.declaration_state_id, name: task.declaration_state_name },
        productType: { id: task.product_type_id, name: task.product_type_name },
        updatedBy: task.changer_id && task.changer_name ? { id: task.changer_id, name: task.changer_name } : null,
      },
    };
  }

  public static manyFromApi(tasks: DnsTaskApi[]): DnsTask[] {
    return tasks.map((task) => this.oneFromApi(task));
  }

  public static acceptFailResult(result: DnsTaskAcceptFailedResultApi): DnsTaskAcceptFailedResult {
    return {
      id: result.customs_task_id,
      reason: result.customs_task_reasons,
    };
  }
}
