import React, { FC, useCallback, useMemo } from 'react';
import { Button, message, Table } from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import * as Icons from '@ant-design/icons';

import { Parcel } from '../types';
import { ParcelService } from '../services';

export const ParcelsTable: FC<Partial<TableProps<Parcel>>> = (props) => {
  const exportManifest = useCallback(async (parcelId: string | number) => {
    message.loading({ key: '1', content: 'Əməliyyat aparılır.', duration: null });
    const result = await ParcelService.getManifests(parcelId);

    if (result.status === 200) {
      message.destroy();
      window.open(result.data.file, '_blank');
    } else {
      message.error({ key: '1', content: result.data });
    }
  }, []);

  const columns = useMemo<ColumnType<Parcel>[]>(
    () => [
      {
        key: 'id',
        dataIndex: 'id',
        title: 'Kod',
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Ad',
        render: (value) => `Koli #${value}`,
      },
      {
        key: 'declarationsCount',
        dataIndex: ['counts', 'declarations'],
        title: 'Bağlama sayı',
      },
      {
        key: 'manifest',
        title: 'Manifest',
        render: (value, row) => {
          return (
            <Button size='small' type='link' icon={<Icons.CloudDownloadOutlined />} onClick={() => exportManifest(row.id)}>
              Manifest
            </Button>
          );
        },
      },
      {
        key: 'username',
        dataIndex: ['user', 'name'],
        title: 'Təhkim olunan',
      },
    ],
    [exportManifest],
  );

  return <Table size='small' bordered={true} pagination={{ pageSize: 10, hideOnSinglePage: true }} columns={columns} rowKey='id' {...props} />;
};
