import React, { createContext, FC, useReducer } from 'react';
import { meContextReducer } from './reducer';
import { initialMeContextState } from './state';
import { IMeContext } from './types';
import { useBootstrapMeContext } from './hooks';

export const MeContext = createContext<IMeContext>({
  state: initialMeContextState,
  dispatch: () => null,
});

export const MeProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(meContextReducer, initialMeContextState);

  useBootstrapMeContext(state, dispatch);

  return <MeContext.Provider value={{ state, dispatch }}>{children}</MeContext.Provider>;
};
