import React, { Fragment, useContext } from 'react';
import { Button } from 'antd';
import { ParcelSelection } from './parcel-selection';
import { ParcelContext } from '../contexts';

export const ParcelProcessor = () => {
  const { selectionDialogVisible, closeParcel, openSelectionDialog, closeSelectionDialog, handleSubmitSucceed, parcels, currentParcel, onSetQuery } = useContext(
    ParcelContext,
  );

  return (
    <Fragment>
      {!currentParcel ? (
        <Button onClick={openSelectionDialog} size='small' type='link'>
          Koli seç
        </Button>
      ) : (
        <Button onClick={closeParcel} size='small' type='link'>
          {currentParcel.name}. kolini bağla
        </Button>
      )}
      <ParcelSelection
        onSetQuery={onSetQuery}
        allParcels={parcels?.all || []}
        availableParcels={parcels?.available || []}
        visible={selectionDialogVisible}
        onClose={closeSelectionDialog}
        onSubmitSucceed={handleSubmitSucceed}
      />
    </Fragment>
  );
};
