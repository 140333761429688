import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { ApiResult } from '../../../shared/utils/api-result';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';

import { BasketMappers } from '../mappers';
import { Basket } from '../types';
import { NextTableServerSideData } from '../../../shared/modules/next-table/types';
import { formDataFlat } from '../../../shared/utils/form-data-flat';

export class BasketService {
  public static async getAll(query: any = {}): Promise<ApiResult<200, Basket[], NextTableServerSideData> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data, total } = await response.json();
        const mappedData = BasketMappers.manyFromApi(data);

        return new ApiResult(200, mappedData, { total: total });
      } else {
        return new ApiResult(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }

  public static async createOrUpdate(name: string, id?: string | number): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = id ? urlMaker('/api/warehouse/baskets/update') : urlMaker('/api/warehouse/baskets/create');
    const body = new FormData();
    appendToFormData({ basket_name: name, basket_id: id }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async remove(id: (string | number)[]): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets/cancel', { basket_id: id });

    try {
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async close(id: number | string): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets/unselect');
    const body = new FormData();
    appendToFormData({ basket_id: id }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async choose(id: number | string): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets/select');
    const body = new FormData();
    appendToFormData({ basket_id: id }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async exportAsExcel({ declared }: { declared?: boolean } = {}): Promise<ApiResult<200, Blob> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets/export', { customs: declared });

    try {
      const response = await caller(url);

      if (response.ok) {
        const blob = await response.blob();

        return new ApiResult(200, blob, null);
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();
        return new ApiResult(400, Object.values(errors).flat().join(', '), null);
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Sorğu göndərilə bilmədi. İnternet bağlantınızı yoxlayın.', null);
    }
  }

  public static async transfer(trackCodes: string[]): Promise<ApiResult<200> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/baskets/transfer');
    const body = new FormData();
    appendToFormData(formDataFlat({ track_code: trackCodes }), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResult(200, null, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();
        return new ApiResult(400, Object.values(errors).flat().join('. '), null);
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
