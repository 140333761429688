import { useQuery } from '../../../shared/modules/use-query/hooks';
import { IBranch } from '../types';
import { useCallback, useMemo } from 'react';
import { BranchService } from '../services';

export const useBranchList = () => {
    return useQuery<IBranch[]>(
        useCallback(async () => {
            const result = await BranchService.getAll();

            if (result.status === 200) {
                return result.data;
            } else {
                return [];
            }
        }, []),
        useMemo(() => ({ initialData: [] }), []),
    );
};
