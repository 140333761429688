import React, { lazy, Suspense, useContext } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { SuspenseSpin } from '../shared/styled/spin';
import { Location } from 'history';

import { AppLayout } from '../modules/layout/containers';
import { IMeContext } from '../modules/me/context/types';
import { MeContext } from '../modules/me/context/context';
import { CounterProvider } from '../modules/counter/context';

const MeModule = lazy(() => import('../modules/me'));
const DeclarationsModule = lazy(() => import('../modules/declarations'));
const DeclarationsModalRouter = lazy(() => import('../modules/declarations/router/declarations.modal-router'));
const StatisticsModule = lazy(() => import('../modules/statistics'));
const FlightsModule = lazy(() => import('../modules/flights'));
const QuickAcceptModule = lazy(() => import('../modules/quick-accept'));
const CustomsModule = lazy(() => import('../modules/customs'));
const SupportModule = lazy(() => import('../modules/support'));
const BasketsModule = lazy(() => import('../modules/baskets'));
const RefundsModule = lazy(() => import('../modules/refunds'));

export const MainRouter = () => {
  const location = useLocation<{ background?: Location }>();
  const { state } = location;
  const me = useContext<IMeContext>(MeContext);

  if (me.state.user.loading) {
    return <SuspenseSpin />;
  }

  if (!me.state.user.data) {
    return (
      <Suspense fallback={<SuspenseSpin />}>
        <Switch>
          <Route path='/' component={MeModule} />
        </Switch>
      </Suspense>
    );
  }

  return (
    <CounterProvider>
      <AppLayout>
        <Suspense fallback={<SuspenseSpin />}>
          <Switch location={state?.background || location}>
            <Route path='/statistics' component={StatisticsModule} />
            <Route path='/declarations' component={DeclarationsModule} />
            <Route path='/flights' component={FlightsModule} />
            <Route path='/quick_accept' component={QuickAcceptModule} />
            <Route path='/customs' component={CustomsModule} />
            <Route path='/supports' component={SupportModule} />
            <Route path='/baskets' component={BasketsModule} />
            <Route path='/refunds' component={RefundsModule} />
            <Redirect to='/declarations' />
          </Switch>
          <Switch>
            <Route path='/declarations' component={DeclarationsModalRouter} />
          </Switch>
        </Suspense>
      </AppLayout>
    </CounterProvider>
  );
};
