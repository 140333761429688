import { Parcel, ParcelApi, ParcelCreateResult, ParcelCreateResultApi } from '../types';

export class ParcelMappers {
  public static oneFromApi(parcel: ParcelApi): Parcel {
    return {
      id: parcel.id,
      name: parcel.box,
      user: parcel.user_id ? { id: parcel.user_id, name: parcel.user_name || '' } : null,
      counts: { declarations: parcel.declaration_count },
      createdAt: parcel.created_at,
    };
  }

  public static manyFromApi(parcels: ParcelApi[]): Parcel[] {
    return parcels.map((parcel) => this.oneFromApi(parcel));
  }

  public static createResultFromApi(result: ParcelCreateResultApi): ParcelCreateResult {
    return {
      id: result,
    };
  }
}
