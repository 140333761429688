import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { ApiResult } from '../../../shared/utils/api-result';

import { CounterMappers } from '../mappers';
import { Counter } from '../types';

export class CounterService {
  public static async get(): Promise<ApiResult<200, Counter> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/notification/count');

    try {
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        const data = await response.json();
        const mappedData = CounterMappers.fromApi(data);

        return new ApiResult(200, mappedData, null);
      } else {
        return new ApiResult(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
