import { ApiResult } from '../../../shared/utils/api-result';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';

import { DeclarationMappers } from '../mappers';
import { DeclarationProformaInvoice } from '../types/proforma-invoice';

export class DeclarationsProformaInvoiceService {
  public static async getOneById(
    ids: (string | number)[],
  ): Promise<ApiResult<200, DeclarationProformaInvoice> | ApiResult<422, string[]> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/declaration/proforma', { declaration_id: ids });

    try {
      const response = await caller(url);

      if (response.ok) {
        const data = await response.json();
        const mappedData = DeclarationMappers.ProformaInvoice.oneFromApi(data);

        return new ApiResult(200, mappedData, null);
      } else if (response.status === 422) {
        const { errors } = await response.json();
        const mappedErrors = DeclarationMappers.ProformaInvoice.errorsFromApi(errors);
        return new ApiResult(422, mappedErrors, null);
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
