import { Button, Form, message, Modal, Select } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ParcelService } from '../services';
import { Parcel } from '../types';
import { selectFilterOption } from '../../../shared/modules/form/utils';
import { ParcelsTable } from '../components/table';
import { useBranchList } from '../../branches/hooks';

export const ParcelSelection: FC<{
  onSubmitSucceed?: () => any;
  onSetQuery: (field: string, val: any) => any;
  onClose?: () => any;
  visible?: boolean;
  availableParcels: Parcel[];
  allParcels: Parcel[];
}> = ({ onSubmitSucceed, onClose, visible, availableParcels, allParcels, onSetQuery }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedParcelId, setSelectedParcelId] = useState<string | null>(null);
  const [branchId, setBranchId] = useState();

  const branches = useBranchList();

  const createParcel = useCallback(async () => {
    setSubmitting(true);
    const result = await ParcelService.create({ branch_id: branchId });

    if (result.status === 200) {
      await onSubmitSucceed?.();
    }
    setSubmitting(false);
  }, [onSubmitSucceed, branchId]);

  const chooseParcel = useCallback(async () => {
    setSubmitting(true);
    const result = await ParcelService.choose(selectedParcelId || 0);

    if (result.status === 200) {
      await onSubmitSucceed?.();
    } else {
      message.error(result.data);
    }
    setSubmitting(false);
  }, [onSubmitSucceed, selectedParcelId]);

  useEffect(() => {
    setSelectedParcelId(null);
  }, [visible]);

  const parcelOptions = useMemo(
    () =>
      availableParcels.map((parcel) => (
        <Select.Option key={parcel.id} value={parcel.id.toString()}>
          Koli #{parcel.name} - Bağlama sayı: {parcel.counts.declarations}
        </Select.Option>
      )),
    [availableParcels],
  );

  const branchOptions = useMemo(
    () =>
      branches.data?.map((branch) => (
        <Select.Option key={branch.id} value={branch.id.toString()}>
          {branch.name}
        </Select.Option>
      )),
    [branches.data],
  );

  const footer = useMemo(
    () => [
      <Button onClick={onClose} key='close'>
        Bağla
      </Button>,
      <Button loading={submitting} disabled={!selectedParcelId} onClick={chooseParcel} type='primary' key='change'>
        Dəyişdir
      </Button>,
      <Button loading={submitting} disabled={!!selectedParcelId || !branchId} onClick={createParcel} type='primary' key='create'>
        Yenisini aç
      </Button>,
    ],
    [chooseParcel, createParcel, onClose, selectedParcelId, submitting, branchId],
  );

  return (
    <Modal width={576} visible={visible} onCancel={onClose} title='Koli əməliyyatları' footer={footer}>
      <Form>
        <Select
          allowClear={true}
          showSearch={true}
          filterOption={selectFilterOption}
          value={branchId || undefined}
          onChange={(value: any) => {
            onSetQuery("branch_id", value);
            setBranchId(value || null)
          }}
          placeholder='Filial seçin...'
          style={{ display: 'block', marginBottom: 20 }}
        >
          {branchOptions}
        </Select>
        <Select
          allowClear={true}
          showSearch={true}
          filterOption={selectFilterOption}
          value={selectedParcelId || undefined}
          onChange={(value: any) => setSelectedParcelId(value || null)
          }
          placeholder='Koli seçin...'
          style={{ display: 'block' }}
        >
          {parcelOptions}
        </Select>
      </Form>
      <div style={{ marginTop: 24, marginBottom: -24 }}>
        <ParcelsTable dataSource={allParcels} />
      </div>
    </Modal>
  );
};
