import { IBranch, IBranchApi } from '../types';

export class BranchMappers {
  public static oneFromApi(data: IBranchApi): IBranch {
    return {
      id: data.id,
      address: data.address,
      branchName: data.branch_name,
      descr: data.descr,
      email: data.email,
      isBranch: !!data.is_branch,
      mapAddress: data.map_address,
      name: data.name,
      parentId: data.parent_id,
      phone: data.phone,
      workinghours: data.workinghours,
    };
  }

  public static manyFromApi(data: IBranchApi[]): IBranch[] {
    return data.map((item) => BranchMappers.oneFromApi(item));
  }
}
