import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import {BranchMappers  } from '../mappers';
import { ApiResult } from '../../../shared/utils/api-result';
import { IBranch } from '../types';

export class BranchService {
  public static async getAll(): Promise<ApiResult<200, IBranch[]> | ApiResult<400 | 500, string>> {
    try {
      const url = urlMaker('/api/admin/branches');

      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        const mappedData = BranchMappers.manyFromApi(data);
        return new ApiResult(200, mappedData, null);
      } else if (response.status === 400) {
        const { errors } = await response.json();
        return new ApiResult(400, Object.values(errors).flat().join('. '), null);
      } else {
        return new ApiResult(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
