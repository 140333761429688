import { LoginFormData, Me, LoginApiFormData, LoginApiFormErrors, LoginFormErrors, Auth, MeApi } from '../types';

export class MeMappers {
  public static meFromApi(raw: MeApi): Me {
    return {
      id: raw.id,
      firstName: raw.name,
      lastName: raw.surname,
      email: raw.email,
      role: (() => {
        switch (raw.admin) {
          case 1:
            return 'admin';
          case 2:
            return 'warehouseman';
          case 3:
            return 'back_office';
          default:
            return 'client';
        }
      })(),
    };
  }

  public static loginFormToApi(formData: LoginFormData): LoginApiFormData {
    return {
      email: formData.email,
      password: formData.password,
    };
  }

  public static loginFormErrorsFromApi(errors: LoginApiFormErrors): LoginFormErrors {
    return {
      email: errors.email,
      password: errors.password,
    };
  }

  public static loginFormSuccessFromApi(response: any): Auth {
    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      tokenType: response.token_type,
      expiresIn: response.expires_in,
    };
  }
}
