import React, { useCallback, useMemo } from 'react';
import { Tag, Tooltip } from 'antd';
import * as Icons from '@ant-design/icons';

import { useQuery } from '../../../shared/modules/use-query/hooks';

import { CustomsPingService } from '../services/ping';
import { CustomsPingResult } from '../types/ping';

export const CustomsPing = () => {
  const { data, loading } = useQuery<CustomsPingResult | null>(
    useCallback(async () => {
      if (process.env.NODE_ENV !== 'development') {
        const result = await CustomsPingService.get();

        return result.data;
      } else {
        return { status: 'warning' };
      }
    }, []),
    useMemo(() => ({ initialData: null }), []),
  );

  const status = useMemo(() => (data?.status ? data.status : loading ? 'processing' : 'warning'), [data, loading]);

  const icon = useMemo(() => {
    switch (status) {
      case 'success':
        return <Icons.CheckCircleOutlined />;
      case 'error':
        return <Icons.CloseCircleOutlined />;
      case 'warning':
        return <Icons.ExclamationCircleOutlined />;
      default:
        return <Icons.SyncOutlined spin={true} />;
    }
  }, [status]);

  const title = useMemo(() => {
    switch (status) {
      case 'success':
        return 'İşləkdir';
      case 'error':
        return 'İşlək deyil';
      case 'warning':
        return 'Təyin olunmayıb';
      default:
        return 'Gözlənilir';
    }
  }, [status]);

  return (
    <Tooltip title={title}>
      <Tag icon={icon} color={status}>
        DGK
      </Tag>
    </Tooltip>
  );
};
