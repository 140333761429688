import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { message, Modal } from 'antd';
import { QueryResult } from '../../../shared/modules/use-query/types';
import { MeContext } from '../../me/context/context';
import { useBaskets } from '../hooks/use-baskets';
import { BasketService } from '../services';
import { Basket } from '../types';

export const BasketContext = createContext<{
  selectionDialogVisible: boolean;
  setSelectionDialogVisible: (value: boolean) => void;
  closeParcel: () => void;
  openSelectionDialog: () => void;
  closeSelectionDialog: () => void;
  reFetchBaskets: () => Promise<void>;
  handleSubmitSucceed: any;
  baskets: QueryResult<Basket[]> | null;
  currentBasket: null | Basket;
}>({
  selectionDialogVisible: false,
  setSelectionDialogVisible: () => null,
  closeParcel: async () => {},
  reFetchBaskets: async () => {},
  openSelectionDialog: () => {},
  closeSelectionDialog: () => {},
  handleSubmitSucceed: () => {},
  baskets: null,
  currentBasket: null,
});

export const BasketProvider: FC = ({ children }) => {
  const { state: userState } = useContext(MeContext);
  const [selectionDialogVisible, setSelectionDialogVisible] = useState<boolean>(false);

  const baskets = useBaskets();

  const { reFetch: reFetchBaskets } = baskets;

  const currentBasket = useMemo<Basket | null>(() => {
    return (
      baskets.data.find((basket) => {
        return !!basket.users.find((user) => user.id === userState.user.data?.id);
      }) || null
    );
  }, [baskets.data, userState.user.data]);

  const closeParcel = useCallback(() => {
    const action = async () => {
      const result = await BasketService.close(currentBasket?.id || 0);

      if (result.status === 200) {
        await reFetchBaskets();
      } else {
        message.error(result.data);
      }
    };

    Modal.confirm({ title: 'Təsdiqlə', content: 'Səbəti bağlamaq istədiyinizə əminsinizmi?', onOk: action });
  }, [currentBasket, reFetchBaskets]);

  const openSelectionDialog = useCallback(async () => {
    setSelectionDialogVisible(true);
  }, []);

  const closeSelectionDialog = useCallback(() => {
    setSelectionDialogVisible(false);
  }, []);

  const handleSubmitSucceed = useCallback(async () => {
    closeSelectionDialog();
    await reFetchBaskets();
  }, [closeSelectionDialog, reFetchBaskets]);

  return (
    <BasketContext.Provider
      value={{
        selectionDialogVisible,
        setSelectionDialogVisible,
        closeParcel,
        openSelectionDialog,
        closeSelectionDialog,
        handleSubmitSucceed,
        baskets,
        currentBasket,
        reFetchBaskets,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};
