import { Basket, BasketApi } from '../types';

export class BasketMappers {
  public static oneFromApi(parcel: BasketApi): Basket {
    return {
      id: parcel.id,
      name: parcel.basket_name,
      users: parcel.users,
      counts: { declarations: parcel.declaration_count },
      createdAt: parcel.created_at,
    };
  }

  public static manyFromApi(parcels: BasketApi[]): Basket[] {
    return parcels.map((parcel) => this.oneFromApi(parcel));
  }
}
