import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Form, Menu, message, Modal, Select } from 'antd';
import { v1 as uuid } from 'uuid';

import { selectFilterOption } from '../../../shared/modules/form/utils';

import { BasketsTable } from '../components/table';
import { BasketService } from '../services';
import { Basket } from '../types';

export const BasketSelection: FC<{
  onSubmitSucceed?: () => any;
  onClose?: () => any;
  visible?: boolean;
  baskets: Basket[];
}> = ({ onSubmitSucceed, onClose, visible, baskets }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedBasketId, setSelectedBasketId] = useState<string | null>(null);

  const chooseBasket = useCallback(async () => {
    setSubmitting(true);
    const result = await BasketService.choose(selectedBasketId || 0);

    if (result.status === 200) {
      await onSubmitSucceed?.();
    } else {
      message.error(result.data);
    }
    setSubmitting(false);
  }, [onSubmitSucceed, selectedBasketId]);

  const exportAsExcel = useCallback(async (query: { declared?: boolean } = {}) => {
    message.loading({ key: '1', content: 'Əməliyyat aparılır.', duration: null });
    const result = await BasketService.exportAsExcel(query);

    if (result.status === 200) {
      message.destroy();
      const anchor = document.createElement('a');
      const url = window.URL.createObjectURL(result.data);

      document.body.appendChild(anchor);
      anchor.href = url;
      anchor.download = uuid();
      anchor.click();

      window.URL.revokeObjectURL(url);
    } else {
      message.error({ key: '1', content: result.data });
    }
  }, []);

  useEffect(() => {
    setSelectedBasketId(null);
  }, [visible]);

  const basketOptions = useMemo(
    () =>
      baskets.map((basket) => (
        <Select.Option key={basket.id} value={basket.id.toString()}>
          {basket.name} - Bağlama sayı: {basket.counts.declarations}
        </Select.Option>
      )),
    [baskets],
  );

  const footer = useMemo(
    () => [
      <Button onClick={onClose} key='close'>
        Bağla
      </Button>,
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={() => exportAsExcel({ declared: true })}>Bəyanlı</Menu.Item>
            <Menu.Item onClick={() => exportAsExcel({ declared: false })}>Bəyansız</Menu.Item>
            <Menu.Item onClick={() => exportAsExcel()}>Hamısı</Menu.Item>
          </Menu>
        }
      >
        <Button key='export-as-excel'>Excel export</Button>
      </Dropdown>,
      <Button loading={submitting} disabled={!selectedBasketId} onClick={chooseBasket} type='primary' key='change'>
        Dəyişdir
      </Button>,
    ],
    [chooseBasket, exportAsExcel, onClose, selectedBasketId, submitting],
  );

  return (
    <Modal width={576} visible={visible} onCancel={onClose} title='Səbət əməliyyatları' footer={footer}>
      <Form>
        <Select
          allowClear={true}
          showSearch={true}
          filterOption={selectFilterOption}
          value={selectedBasketId || undefined}
          onChange={(value: any) => setSelectedBasketId(value || null)}
          placeholder='Səbət seçin...'
          style={{ display: 'block' }}
        >
          {basketOptions}
        </Select>
      </Form>
      <div style={{ marginTop: 24, marginBottom: -24 }}>
        <BasketsTable dataSource={baskets} />
      </div>
    </Modal>
  );
};
