import { ApiResult } from '../../../shared/utils/api-result';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';

import { DeclarationMappers } from '../mappers';
import { DeclarationSticker } from '../types/sticker';

export class DeclarationsStickerService {
  public static async getOneById(id: string | number): Promise<ApiResult<200, DeclarationSticker> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/declaration/info_print', { declaration_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        const mappedData = DeclarationMappers.Sticker.oneFromApi(data);

        return new ApiResult(200, mappedData, null);
      } else {
        return new ApiResult(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new ApiResult(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
